const ContactTrigger = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="55"
    viewBox="0 0 40 55"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(8.000000, 8.000000)">
        <polygon id="Path" points="0 0 24 0 24 24 0 24" />
        <path
          d="M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 Z M20,8 L12,13 L4,8 L4,6 L12,11 L20,6 L20,8 Z"
          id="envelop"
        />
      </g>
      <g id="arrow" transform="translate(14.000000, 47.000000)">
        <polygon points="1.41 0.59 6 5.17 10.59 0.59 12 2 6 8 0 2" />
      </g>
      <circle id="oval" strokeWidth="3" cx="20" cy="20" r="18.5" />
    </g>
  </svg>
);

export default ContactTrigger;

import React from 'react';
import { func, string } from 'prop-types';
import Link from 'next/link';
import { toKey } from '@/helpers';

const CustomLink = ({ name, title, to, handleClick, renderIcon }) => {
  return (
    <Link href={to}>
      <a
        data-testid={`link-${toKey(name)}`}
        role="button"
        onClick={(e) => handleClick(e, to)}
        onKeyDown={(e) => handleClick(e, to)}
        tabIndex={0}
        title={title}
      >
        {renderIcon ? renderIcon() : name}
      </a>
    </Link>
  );
};

CustomLink.propTypes = {
  name: string.isRequired,
  title: string.isRequired,
  to: string.isRequired,
  handleClick: func,
  renderIcon: func,
};

CustomLink.defaultProps = {
  handleClick: null,
  renderIcon: null,
};

export default CustomLink;

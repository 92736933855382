import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

const SHome = styled(({ ...rest }) => <motion.div {...rest} />)`
  ${(props) => props.theme.layout.routeMotion}
  height: 100vh;
  color: white;
  background: url('../images/bg_body_mobile.jpg') no-repeat scroll 95% 0px;
  background-size: cover;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.phablet}) {
    background: url('./images/bg_body.jpg') no-repeat 40% 0 / cover;
  }
`;

const SHomeWrapper = styled.div`
  height: 100%;
  background: ${(props) => `rgba(${props.theme.color.primary.rgb}, 0.7)`};
  padding: ${(props) => props.theme.spacing.rem(0, 1)};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.phablet}) {
    padding: ${(props) => props.theme.spacing.rem(10, 2, 0)};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 60%;
    float: right;
    padding: ${(props) => props.theme.spacing.rem(0, 10)};
    background: ${(props) => `rgba(${props.theme.color.primary.rgb}, 0.2)`};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 55%;
    padding: ${(props) => props.theme.spacing.rem(0, 15)};
    background: none;
  }
`;

const SHomeContent = styled.section`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.phablet}) {
    width: 60%;
    float: right;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    float: none;
  }
`;

const SHomeHeader = styled.header`
  padding-top: 15vh;
`;

const SHomeTitle = styled.h1`
  font-size: ${(props) => props.theme.font.size.h2};
  font-weight: ${(props) => props.theme.font.weight.regular};
  margin-bottom: ${(props) => props.theme.spacing.rem(2)};
  span, strong {
    display: block;
  }
  strong {
    font-weight: ${(props) => props.theme.font.weight.light};
    font-size: ${(props) => props.theme.font.size.body};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop}) {
    font-size: ${(props) => props.theme.font.size.h1};
    /* margin-bottom: ${(props) => props.theme.spacing.baseline(2)}; */
    strong {
      font-size: ${(props) => props.theme.font.size.h3};
    }
  }
`;

const SHomeMain = styled.main`
  font-size: ${(props) => props.theme.font.size.body};
  margin-bottom: ${(props) => props.theme.spacing.rem(1)};
  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop}) {
    margin-bottom: ${(props) => props.theme.spacing.rem(3)};
    font-size: ${(props) => props.theme.font.size.medium};
  }
`;

const SHomeNav = styled.nav`
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (min-width: ${(props) =>
        props.theme.breakpoints.phablet}) {
      flex-wrap: nowrap;
    }
  }
  li {
    flex-grow: 1;
    width: 45%;
    text-align: center;
    margin-bottom: ${(props) => props.theme.spacing.rem(1)};
    &:first-child {
      margin-right: ${(props) => props.theme.spacing.rem(1)};
    }

    @media screen and (min-width: ${(props) =>
        props.theme.breakpoints.phablet}) {
      width: auto;
      &:nth-child(2) {
        margin-right: ${(props) => props.theme.spacing.rem(1)};
      }
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: ${(props) => props.theme.spacing.rem(1, 2)};
      text-transform: uppercase;
      border: 1px solid white;
      color: white;
      background: rgba(255, 255, 255, 0.1);
      font-weight: ${(props) => props.theme.font.weight.bold};
      font-size: ${(props) => props.theme.font.size.body};
      text-decoration: none;
      letter-spacing: ${(props) => props.theme.font.letterSpacing};
      @media screen and (min-width: ${(props) =>
          props.theme.breakpoints.laptop}) {
        font-size: ${(props) => props.theme.font.size.medium};
      }
    }
    a:hover {
      background: white;
      color: ${(props) => props.theme.color.accent.main};
      transition: all ${(props) => props.theme.transition.default};
      text-decoration: none;
    }
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.tabletLandscape}) {
    ul {
      justify-content: flex-start;
    }
    li {
      flex-basis: auto;
      a {
        padding: ${(props) => props.theme.spacing.rem(2, 3)};
      }
    }
  }
`;

const SHomeNavAbout = styled.li`
  && {
    a {
      border-color: ${(props) => props.theme.color.accent.main};
      color: ${(props) => props.theme.color.accent.main};
      background: ${(props) => `rgba(${props.theme.color.accent.rgb}, 0.1)`};
    }
  }
`;

const SFooter = styled.footer`
  display: none;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    position: absolute;
    bottom: ${(props) => props.theme.spacing.baseline(2)};
    right: ${(props) => props.theme.spacing.baseline(2)};
    display: block;
    line-height: 1;
    font-style: italic;
    color: white;

    strong {
      color: ${(props) => props.theme.color.accent.main};
      font-weight: ${(props) => props.theme.font.weight.regular};
    }

    span {
    }
  }
`;

const SContactTrigger = styled.button`
  position: fixed;
  left: 50%;
  bottom: 0;
  display: block;
  transform: translate(-50%, 0);
  margin-top: -4em;
  padding: 0.5em;
  text-align: center;
  color: white;
  animation: 2s ${(props) => props.theme.animation.bounce} 1s infinite;
  svg {
    display: block;
    #envelop,
    #arrow {
      fill: white;
    }
    #oval {
      stroke: white;
    }
  }
  &:hover {
    svg {
      #envelop,
      #arrow {
        fill: ${(props) => props.theme.color.accent.main};
      }
      #oval {
        stroke: ${(props) => props.theme.color.accent.main};
      }
    }
  }
`;

export {
  SHome,
  SHomeWrapper,
  SHomeContent,
  SHomeHeader,
  SHomeTitle,
  SHomeMain,
  SHomeNav,
  SHomeNavAbout,
  SFooter,
  SContactTrigger,
};

import styled, { keyframes, css } from 'styled-components';

const highlight = keyframes`
  0% { width: 0; height: 90%; } 100% { width: 102%; height: 105%; }
`;

const colorWhite = keyframes`
  0% { color: inherit } 100% { color: white};
`;

const colorAccent = keyframes`
  0% { color: inherit } 100% { color: #ff5252 };
`;

const SHighlighted = styled.span`
 && {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 0 0.2em;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: -3px;
    width: 0;
    height: 85%;
    transform: skewX(-0.5deg) rotate(-0.3deg);
    transform-origin: left bottom;
    animation: ${highlight} 1s cubic-bezier(1, 0.64, 0.03, 0.99) 0.5s forwards;
  }
  ${({ light }) => {
    if (light) {
      return css`
        animation: ${colorAccent} 1s cubic-bezier(1, 0.64, 0.03, 0.99) 0.5s
          forwards;
        &::before {
          background: rgba(255, 255, 255, 0.8);
        }
      `;
    }
    return css`
      animation: ${colorWhite} 1s cubic-bezier(1, 0.64, 0.03, 0.99) 0.5s
        forwards;
      &::before {
        background: ${(props) => `rgba(${props.theme.color.accent.rgb}, 0.95)`};
        box-shadow: 2px 0 0 2px
          ${(props) => `rgba(${props.theme.color.accent.rgb}, 0.8)`} inset;
      }
    `;
  }}
`;

export default SHighlighted;

import { withTheme } from 'styled-components';
import { useRouter } from 'next/router';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getNavItemProps } from '@/helpers';
import ContactTriggerIcon from '@/components/icons/ContactTriggerIcon';
import Highlighted from '@/components/Highlighted';
import Link from '@/components/Link';
import { pageFlow, positionStart } from '@/recoil/atoms';
import {
  SHome,
  SHomeWrapper,
  SHomeContent,
  SHomeMain,
  SHomeHeader,
  SHomeTitle,
  SHomeNav,
  SFooter,
  SContactTrigger,
} from './styles';

const Home = () => {
  const router = useRouter();
  const setPosition = useSetRecoilState(positionStart);
  const [flow, setPageFlow] = useRecoilState(pageFlow);
  const { origin, target, effect } = flow;

  const triggerContact = () => {
    setPageFlow({ origin: 'home', target: 'contact', effect: 'slide' });
    router.push('/contact');
  };

  const handleLinkClick = (e) => {
    setPageFlow({ origin: 'home', effect: 'scale' });
    setPosition(e.target.getBoundingClientRect());
  };

  const variants = {
    start: () => {
      return {
        zIndex: 1,
        y: origin === 'contact' && effect === 'slide' ? -1000 : 0,
        opacity: 0,
      };
    },
    end: () => {
      return {
        zIndex: 1,
        y: 0,
        opacity: 1,
      };
    },
    exit: () => {
      return {
        zIndex: 0,
        y: target === 'contact' && effect === 'slide' ? -1000 : 0,
        transition: {
          delay: effect === 'scale' ? 0.75 : 0,
        },
        opacity: 0,
      };
    },
  };

  const navItems = ['about', 'skillsset', 'work'];

  return (
    <SHome
      key="route-home"
      data-testid="home"
      variants={variants}
      initial="start"
      animate="end"
      exit="exit"
      transition={{
        y: { type: 'spring', stiffness: 300, damping: 200 },
      }}
    >
      <SHomeWrapper>
        <SHomeContent>
          <SHomeHeader data-testid="home-header">
            <SHomeTitle>
              <span>Hi There! </span>
              <strong>
                <Highlighted>
                  I am Hélène, a passionate front-end developer.
                </Highlighted>
              </strong>
            </SHomeTitle>
          </SHomeHeader>
          <SHomeMain data-testid="home-main">
            <p>
              After 12 years developing for start ups and web agencies I have
              acquired a transversal knowledge of the core front-end principles:
              HTML5, CSS3, Javascript. With a good eye for design and attention
              to detail, I care as much about the user experience as I do about
              the consistency and scalability of the code I deliver.<br /><br />
              My specialty lies in React and its ecosystem, where I have a proven track record of building high-quality, scalable applications.
            </p>
          </SHomeMain>
          <SHomeNav data-testid="home-nav">
            <ul>
              {navItems.map((item) => (
                <li key={item}>
                  <Link
                    handleClick={handleLinkClick}
                    {...getNavItemProps(item)}
                  />
                </li>
              ))}
            </ul>
          </SHomeNav>
        </SHomeContent>
        <SFooter>
          Hand-coded with love <strong>(and React)</strong> xx
        </SFooter>
      </SHomeWrapper>
      <SContactTrigger
        type="button"
        onClick={triggerContact}
        title="Get to contact page"
      >
        <ContactTriggerIcon />
      </SContactTrigger>
    </SHome>
  );
};

export default withTheme(Home);

/* text gradient:
  background: linear-gradient(90deg,#ff8a00,#e52e71);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
*/

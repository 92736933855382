import { string, bool } from 'prop-types';
import SHighlighted from './styles';

const Highlighted = ({ children, light }) => (
  <SHighlighted light={light}>{children}</SHighlighted>
);

Highlighted.propTypes = {
  children: string.isRequired,
  light: bool,
};

Highlighted.defaultProps = {
  light: false,
};

export default Highlighted;
